<template>
	<div class="navmb">
		<div class="flex flexa">
			<span class="">{{ title }}</span>
		</div>
	</div>
	<div class="bgf mart20">
		<ul class=" bgf h100 flex">
			<li class="flex1 flex flexa">
				<div class="w100 tc point" :class="{
				colzhuti: param.userStatus == 3
			}" @click="param.userStatus = 3; getList(1)">
					<p class="font14 padb10">客户工作中</p>
					<h1 class="font28 fontw6">{{
				param.type == 2 ? statusInfo.technologyUserWorkTotal : statusInfo.wrongUserWorkTotal }}</h1>
				</div>
			</li>
			<li class="flex1 flex flexa">
				<el-divider direction="vertical" style="height: 64px; background-color: #E5E5E5" />
				<div class="w100 tc point" :class="{
				colzhuti: param.userStatus == 2
			}" @click="param.userStatus = 2; getList(1)">
					<p class="font14 padb10">支持工作中</p>
					<h1 class="font28 fontw6">{{
				param.type == 2 ? statusInfo.technologySupportWorkTotal : statusInfo.wrongSupportWorkTotal }}
					</h1>
				</div>
			</li>
			<li class="flex1 flex flexa">
				<el-divider direction="vertical" style="height: 64px; background-color: #E5E5E5" />
				<div class="w100 tc point" :class="{
				colzhuti: param.userStatus == 10
			}" @click="param.userStatus = 10; getList(1)">
					<p class="font14 padb10">已关闭</p>
					<h1 class="font28 fontw6">{{
				param.type == 2 ? statusInfo.technologyCloseTotal : statusInfo.wrongCloseTotal }}</h1>
				</div>
			</li>
			<li class="flex1 flex flexa">
				<el-divider direction="vertical" style="height: 64px; background-color: #E5E5E5" />
				<div class="w100 tc point" :class="{
				colzhuti: param.userStatus == ''
			}" @click="param.userStatus = ''; getList(1)">
					<p class="font14 padb10">全部</p>
					<h1 class="font28 fontw6">{{
				param.type == 2 ? statusInfo.technologyOrderTotal : statusInfo.wrongOrderTotal }}</h1>
				</div>
			</li>
		</ul>
	</div>
	<div class="bgf padd20 mart20 marb20">
		<div class="flexab m-chotop">
			<div class="left">
				<el-button v-if="$route.params.type == 2" size="large" @click="$router.push('/submit/tech')"
					type="primary">+创建技术服务请求</el-button>
				<el-button v-else-if="$route.params.type == 1" size="large" @click="$router.push('/submit/untech')"
					type="primary">+创建非技术服务请求</el-button>
				<el-select size="large" @change="queryList(1)" v-model="param_form.csi" clearable placeholder="客户服务号">
					<el-option v-for="item in csiList" :key="item.id"
						:label="item.csiNumber + '（' + item.companyName + '）'" :value="item.id" />
				</el-select>
			</div>

			<div class="flex flexa">
				<div class="wmax padr30">
					<el-checkbox-group v-model="param_form.searchType" @change="queryList()">
						<el-checkbox :label=1>我收藏的</el-checkbox>
						<el-checkbox class="marl20" :label=2>我名下的</el-checkbox>
						<el-checkbox class="marl20" :label=3>进行中的</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		<div class="m-flexbox">
			<div class="m-leftbox">
				<div class="item">
					<div class="g-sel_inp">
						<el-select size="large"
							@change="param_form.outTradeNo = '', param_form.problemProfile = '', queryList()"
							v-model="param_form.searchKey" placeholder="问题概要">
							<el-option label="按照请求编号搜索" value="outTradeNo" />
							<el-option label="问题概要" value="problemProfile" />
						</el-select>
						<el-input size="large" v-model="param_form[param_form.searchKey]" placeholder="搜索关键字">
							<template #suffix>
								<el-icon @click="queryList()" color="#FF6A00" class="el-input__icon point">
									<Search />
								</el-icon>
							</template>
						</el-input>
					</div>
				</div>
				<div class="item">
					<div class="g-sel_inp">
						<el-button size="large" class="w68" style="margin-right: -1px" @click="() => {
				param_form.time = []
				param_form.createTime = ''
				param_form.endTime = ''
				queryList()
			}">全部</el-button>
						<!-- <el-select size="large" v-model="param.c1" placeholder="全部">
							<el-option v-for="item in []" :key="item.value" :label="item.label" :value="item.value" />
						</el-select> -->
						<el-date-picker v-model="param_form.time" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
							type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
							size="large" @change="(val) => {
				const [createTime, endTime] = val ?? []
				param_form.createTime = createTime
				param_form.endTime = endTime
				queryList()
			}"></el-date-picker>
					</div>
				</div>

				<div class="opts">
					<div class="set">
						<el-icon @click="field.visible = true">
							<img style="width: 1em;height:1em" draggable="false" src="../../assets/imgs/setting.png" />
						</el-icon>
					</div>
					<div class="refresh">
						<el-icon @click="clickRefresh">
							<img style="width: 1em;height:1em" draggable="false" src="../../assets/imgs/refersh.png" />
						</el-icon>
					</div>
					<el-button class="marl20" size="large" @click="clickDc()" type="primary">导出</el-button>
				</div>
			</div>

		</div>
	</div>
	<!-- 表格 -->
	<div class="bgf" v-loading="loading">
		<FieldFilter v-model:visible="field.visible" v-model:list="field.list" :options="field.options"
			@confirm="filterFiled_Confirm" />
		<el-table :data="list" style="width: 100%">
			<el-table-column fixed label="问题概要" class-name="padl20 padr20" :show-overflow-tooltip="{ effect: 'light' }"
				min-width="234px" prop="problemProfile" v-if="map_filedOptions.has('problemProfile')">
				<template #="{ row }">
					<router-link class="bllk block text1 col4c" :to="{
				name: 'serviceInfo',
				params: {
					id: row.id
				},
				state: {
					force: $route.path
				}
			}">{{ row.problemProfile
						}}</router-link>
					<!-- <p class="point" @click="toServiceInfo(row)">{{row.problemProfile}}</p> -->
				</template>
			</el-table-column>
			<el-table-column prop="outTradeNo" fixed min-width="120" label="技术请求编号"
				:show-overflow-tooltip="{ effect: 'light' }" v-if="map_filedOptions.has('outTradeNo')">
			</el-table-column>
			<el-table-column prop="productModelName" min-width="140" label="产品模块"
				:show-overflow-tooltip="{ effect: 'light' }" v-if="map_filedOptions.has('productModelName')">
				<template #default="scope">{{ scope.row.productName }}>{{ scope.row.productModelName }}</template>
			</el-table-column>
			<el-table-column prop="createTime" min-width="140" sortable :show-overflow-tooltip="{ effect: 'light' }"
				label="创建时间" v-if="map_filedOptions.has('createTime')">
			</el-table-column>
			<el-table-column min-width="140" v-if="map_filedOptions.has('lastUpdateTime')" prop="lastUpdateTime"
				label="上次更新时间" :show-overflow-tooltip="{ effect: 'light' }">
			</el-table-column>

			<el-table-column min-width="120" prop="u" sortable label="严重等级" v-if="map_filedOptions.has('u')" :filters="[
				{ text: 'u1', value: 1 },
				{ text: 'u2', value: 2 },
				{ text: 'u3', value: 3 },
				{ text: 'u4', value: 4 },
			]" :filter-method="(val, row, col) => row[col['property']] == val" filter-placement="bottom-end">
				<template #="{ row }">
					<div class="severity lev1" v-if="row.u == 1">
						{{ u[row.u] }}
					</div>
					<div class="severity lev2" v-else-if="row.u == 2">
						{{ u[row.u] }}
					</div>
					<div class="severity lev3" v-else-if="row.u == 3">
						{{ u[row.u] }}
					</div>
					<div class="severity lev4" v-else-if="row.u == 4">
						{{ u[row.u] }}
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="mainContact" label="联系人" :show-overflow-tooltip="{ effect: 'light' }" min-width="80"
				v-if="map_filedOptions.has('mainContact')">
			</el-table-column>

			<el-table-column prop="userStatus" label="状态" :show-overflow-tooltip="{ effect: 'light' }" min-width="70"
				v-if="map_filedOptions.has('userStatus')" :filters="[
				{ text: '待分派', value: 1 },
				{ text: '支持工作中', value: 2 },
				{ text: '客户工作中', value: 3 },
				{ text: '复查更新', value: 4 },
				{ text: '研发工作中', value: 5 },
				{ text: '待研发反馈', value: 6 },
				{ text: '待内部反馈', value: 7 },
				{ text: '方案已提供', value: 8 },
				{ text: '用户请求关闭', value: 9 },
				{ text: '已关闭', value: 10 },
				{ text: '重开审核中', value: 11 },
			]" :filter-method="(val, row, col) => row[col['property']] == val" filter-placement="bottom-end">
				<template #="{ row }">
					<span>{{ userStatus.get(row.userStatus) }}</span>
				</template>
			</el-table-column>

			<el-table-column prop="csi" label="客户服务号" :show-overflow-tooltip="{ effect: 'light' }" min-width="100px"
				v-if="map_filedOptions.has('csi')">
			</el-table-column>
			<el-table-column prop="isCollect" label="收藏" min-width="40">
				<template #="{ row }">
					<div class="point">
						<el-button v-if="row.isCollect == 0" link :disabled="row.disableLoading ?? false"
							@click="collectOrder(row)">
							<template #icon>
								<el-icon size="16">
									<Star />
								</el-icon>
							</template>
						</el-button>
						<el-button v-else-if="row.isCollect == 1" link :disabled="row.disableLoading ?? false"
							@click="collectOrder(row)">
							<template #icon>
								<el-icon color="#ff6a00" size="16">
									<StarFilled />
								</el-icon>
							</template>
						</el-button>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="100">
				<template #="{ row }">
					<span @click="toServiceInfo(row)" class="point colblue font12">查看</span>
				</template>
			</el-table-column> -->
		</el-table>
		<div class="h70 pad20 flexab w100 borbox">
			<p class="font14 cola4">共 {{ total }} 条</p>
			<el-pagination v-model:currentPage="param.pageNumber" @update:page-size="val => {
				param.pageSize = val;
				getList()
			}" @update:current-page="getList" :page-sizes="[5, 10, 15, 20]" :page-size="param.pageSize"
				layout="sizes, prev , jumper , next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { DocumentCopy, Search, StarFilled, Star } from '@element-plus/icons-vue'
import { markRaw } from 'vue'
import { mapGetters } from 'vuex'
import FieldFilter from '@/components/FieldFilter.vue'
export default {
	setup() {
		return {
			userStatus: new Map([
				[1, '待分派'],
				[2, '支持工作中'],
				[3, '客户工作中'],
				[4, '复查更新'],
				[5, '研发工作中'],
				[6, '待研发反馈'],
				[7, '待内部反馈'],
				[8, '方案已提供'],
				[9, '用户请求关闭'],
				[10, '已关闭'],
				[11, '重开审核中'],
			]),
			u: markRaw({ 4: 'U4-服务未丢失', 3: 'U3-服务少量丢失', 2: 'U2-服务严重丢失', 1: 'U1-服务完全丢失' })
		}
	},
	components: {
		Search, Star, StarFilled,
		FieldFilter
	},
	computed: {
		...mapGetters({
			csiList: 'cache/getCsiList',
		}),
		title() {
			if (this.$route.params.type == 1) {
				return '非技术服务请求'
			} else if (this.$route.params.type == 2) {
				return '技术服务请求'
			}
			return ''
		},
		map_filedOptions() {
			return new Set(this.field.confirmList)
		}
	},
	watch: {
		$route(route) {
			this.param.type = this.param_form.type = route.params.type
			this.queryList()
		}
	},
	data() {
		return {
			field: {
				visible: false,
				list: ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName'],
				confirmList: ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName'],
				options: [
					{ value: 'problemProfile', label: '问题概要', readonly: false },
					{ value: 'outTradeNo', label: '技术请求编号', readonly: false },
					{ value: 'productModelName', label: '产品模块', readonly: true },
					{ value: 'createTime', label: '创建时间', readonly: false },
					{ value: 'u', label: '严重等级', readonly: false },
					{ value: 'mainContact', label: '联系人', readonly: false },
					{ value: 'userStatus', label: '状态', readonly: false },
					{ value: 'csi', label: '客户服务号', readonly: false },
					{ value: 'lastUpdateTime', label: '最后更新时间', readonly: false },
				]
			},
			param_form: {
				csi: '',
				searchType: [],
				time: '',
				searchKey: 'problemProfile',
			},
			param: {
				csi: '',
				userStatus: this.$route.query.status ?? '',
				type: this.$route.params.type,
				searchType: '',
				pageNumber: 1,
				pageSize: 10,
			},
			statusInfo: {},
			loading: false,
			list: [],
			total: 0,
		}
	},
	methods: {
		async collectOrder(row) {
			try {
				row.disableLoading = true
				const result = await this.$api.order.collectOrder({ id: row.id })
				if (result) {
					this.$message.success(result.message)
					if (row.isCollect == 0) {
						row.isCollect = 1
					} else if (row.isCollect == 1) {
						row.isCollect = 0
					}
				}
			} catch (e) {
				return console.log(e)
			} finally {
				row.disableLoading = false
			}
		},
		toServiceInfo(row) {
			this.$router.push({
				name: 'serviceInfo',
				params: {
					id: row.id
				}
			})
		},
		async serviceCount() {
			try {
				const result = await this.$api.tech.serviceCount(this.param)
				console.log('result', result)
				this.statusInfo = result
			} catch (e) {
				return console.log(e)
			} finally {

			}
		},
		clickRefresh() {//重置筛选条件
			this.param = {
				csi: '',
				userStatus: '',
				type: this.param.type,
				searchType: '',
				pageNumber: 1,
				pageSize: 10,
			}
			this.param_form = {
				csi: '',
				searchType: [],
				time: '',
				searchKey: 'problemProfile',
			},
				this.getList(1)
		},
		queryList() {
			// if(this.param.searchType == this.param_form.searchType){
			// 	this.param_form.searchType = ''
			// 	this.param.searchType = ''
			// }
			// if(this.param_form.searchType.length > 1){
			// 	this.param_form.searchType.splice(0,1)
			// }
			this.param.csi = this.param_form.csi
			this.param.searchType = this.param_form.searchType ? this.param_form.searchType.join(',') : ''
			this.param.createTime = this.param_form.createTime
			this.param.endTime = this.param_form.endTime
			this.param[this.param_form.searchKey] = this.param_form[this.param_form.searchKey]
			this.getList(1)
			this.serviceCount()
		},
		clickDc() {
			let that = this
			let param = JSON.parse(JSON.stringify(this.param))
			param.csi = this.param_form.csi,
				param.searchType = this.param_form.searchType ? this.param_form.searchType.join(',') : '',
				param.createTime = this.param_form.createTime ? this.param_form.createTime : '',
				param.endTime = this.param_form.endTime ? this.param_form.endTime : '',
				param[this.param_form.searchKey] = this.param_form[this.param_form.searchKey]
			that.$api.tech.checkServiceRequest(param).then(a => {
				if (a) {
					that.$api.tech.exportOrder(param).then(res => {
						console.log(res, 'res--------')
						if (res) {
							const blob = new Blob([res], { type: 'application/octet-stream' }) // 构造一个blob对象来处理数据
							if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
								navigator.msSaveBlob(blob, '导出工单')
							} else {
								const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
								const a = document.createElement('a') //创建a标签
								a.style.display = 'none'
								a.href = href // 指定下载链接
								a.download = '导出工单.xls' //指定下载文件名
								a.click() //触发下载
								URL.revokeObjectURL(a.href) //释放URL对象
							}
						} else {

						}
					})
				}
			})

		},
		async getList(pageNumber) {
			try {
				pageNumber && (this.param.pageNumber = pageNumber)
				this.loading = true
				console.log('this.param', this.param)
				const { records, total } = await this.$api.tech.serviceRequest(this.param)
				this.total = total
				this.list = records
				console.log(records, 'this.lsit')
			} catch (e) {
				return console.log(e)
			} finally {
				this.loading = false
			}
		},
		loadFilterFiled() {
			try {
				this.field.confirmList = this.field.list = JSON.parse(localStorage.getItem('fieldList')) ?? ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
			} catch {
				this.field.list = ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
				this.field.confirmList = ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
			}
		},
		filterFiled_Confirm(event) {
			this.field.confirmList = event
			localStorage.setItem('fieldList', JSON.stringify(event))
		}
	},
	mounted() {
		// 载入字段
		this.loadFilterFiled()
		this.$store.dispatch('cache/getCsiList')
		this.serviceCount()
		this.getList()
	}
}
</script>
<style lang="scss" scoped>
.severity {
	padding-left: 14px;
	position: relative;
	color: #313133;

	&::before {
		content: "";
		display: block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -3px;
	}

	&.lev1 {
		&::before {
			background-color: #e1504a;
		}
	}

	&.lev2 {
		&::before {
			background-color: #ff9006;
		}
	}

	&.lev3 {
		&::before {
			background-color: #06b0ff;
		}
	}

	&.lev4 {
		&::before {
			background-color: #32e6aa;
		}
	}
}

.m-leftbox,
.m-flexbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
}



.m-leftbox {
	.item {
		margin-right: 24px;
	}
}

.g-sel_inp {
	display: flex;
	align-items: center;
	--el-border-radius-base: 0;

	.el-select {
		margin-right: -1px;
		width: 120px;
		flex-shrink: 0;
	}

	.el-select__wrapper.is-focused {
		z-index: 2;
	}
}

.m-chotop {
	margin-bottom: 16px;

	.left {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.el-button--primary {
		margin-right: 24px;
		--el-button-disabled-bg-color: #ff9447;
		--el-button-disabled-border-color: #ff9447;
	}

	--el-border-radius-base: 0;

	.el-select {
		width: 290px;
	}
}

.opts {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #888888;
	cursor: pointer;

	.set {
		margin-right: 12px;
	}
}

.el-button:active {
	z-index: 1;
}
</style>