<template>
    <el-dialog :close-on-click-modal="false"
        :modelValue="visible"
        align-center
        center
        draggable
        :close-on-press-escape="false"
        title="自定义列表字段"
        width="600px"
        @closed="$emit('update:visible', false)"
    >
        <div class="tit-label">请选择你想要显示的列表详细信息</div>
        <el-checkbox-group :modelValue="list" @change="$emit('update:list', $event)">
            <el-row type="flex">
                <el-col
                    v-for="(item, index) in options"
                    :key="index"
                    :span="6"
                >
                    <el-checkbox
                        :disabled="item.readonly??false"
                        :value="item.value"
                        :label="item.label"
                    />
                </el-col>
            </el-row>
        </el-checkbox-group>
        <template #footer>
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button @click="$emit('update:visible', false)">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default() {
                return []
            }
        },
        options: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        confirm() {
            this.$emit('update:visible', false)
            this.$emit('confirm', this.list)
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.tit-label {
    background-color: #0091EA1A;
    color: #0091EA;
    font-size: 12px;
    padding-left: 22px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
}
</style>